<template>
  <div
    class="flex-grow flex-shrink w-full min-w-0 pl-0 pr-0 overflow-auto bg-white settings dark:bg-slate-800"
  >
    <setting-intro-banner
      v-if="!instanceNoExist"
      :header-image="inbox.avatarUrl"
      :header-title="inboxName"
    >
      <woot-tabs
        class="settings--tabs"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
      <div />
    </setting-intro-banner>

    <!-- <not-instance v-else :inbox="inbox" /> -->

    <not-instance
      v-else-if="!loading && instanceNoExist"
      :inbox="inbox"
      :instance-name="instanceApiName"
    />

    <div v-if="!instanceNoExist" class="settings--content">
      <div v-if="loading" class="loading-spinner">
        {{ $t('API_CONTROL.LOADING') }}
      </div>

      <div v-if="!loading">
        <!-- class="settings--content" -->
        <div v-if="showTab('summary')">
          <summary-page
            :inbox="inbox"
            :api-instance="apiInstance"
            :status="apiInstance.status"
          />
        </div>

        <div v-if="showTab('behavior')">
          <behavior-page
            :api-instance="apiInstance"
            :inbox="inbox"
            :api-settings="apiSettings"
            :api-woot="apiWoot"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner.vue';
import BehaviorPage from './apiControlPage/BehaviorPage.vue';
import SummaryPage from './apiControlPage/SummaryPage.vue';
import inboxApiMixin from 'shared/mixins/InboxApiMixin';
import NotInstance from './apiControlPage/NotInstance.vue';
import apiEvolution from 'dashboard/api/apisControl/apiEvolution';

export default {
  components: {
    SettingIntroBanner,
    BehaviorPage,
    SummaryPage,
    NotInstance,
  },
  mixins: [inboxApiMixin],
  data() {
    return {
      selectedTabIndex: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      apiInstance: 'apiEvolution/getInstance',
      apiSettings: 'apiEvolution/getSettings',
      apiWoot: 'apiEvolution/getWoot',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    apiKey() {
      if (this.apiInstance && this.apiInstance.apikey) {
        return this.apiInstance.apikey;
      }
      return undefined;
    },
    apiVersion() {
      if (this.inbox.additional_attributes) {
        return this.inbox.additional_attributes.version;
      }
      return '';
    },
    inboxName() {
      return this.inbox.name;
    },
    instanceNoExist() {
      return this.apiInstance && !!this.apiInstance.error;
    },
    instanceApiName() {
      return this.inbox.webhook_url
        ? this.inbox.webhook_url.split('/webhook/')[1]
        : this.inboxName;
    },
    selectedTabKey() {
      const index = this.selectedTabIndex ? this.selectedTabIndex : 0;
      return this.tabs[index]?.key;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    tabs() {
      let visibleToAllChannelTabs = [
        {
          key: 'summary',
          name: this.$t('INBOX_MGMT.EVOLUTION.TABS.SUMMARY'),
        },
        {
          key: 'behavior',
          name: this.$t('INBOX_MGMT.EVOLUTION.TABS.BEHAVIOR'),
        },
        // {
        //   key: 'system',
        //   name: this.$t('INBOX_MGMT.EVOLUTION.TABS.SYSTEM'),
        // },
        // {
        //   key: 'profile',
        //   name: this.$t('INBOX_MGMT.EVOLUTION.TABS.PROFILE'),
        // },
        // {
        //   key: 'privacy',
        //   name: this.$t('INBOX_MGMT.EVOLUTION.TABS.PRIVACY'),
        // },
      ];
      return visibleToAllChannelTabs;
    },
  },
  watch: {
    apiInstance() {
      if (
        this.apiInstance &&
        !this.apiInstance?.error &&
        this.inbox &&
        this.inbox.webhook_url
      ) {
        const { serverUrl, apikey } = this.apiInstance;
        const url =
          this.inbox.webhook_url.split('/chatwoot/webhook/')[0] || serverUrl;
        this.setUrlApiAxios(url, apikey);

        if (this.selectedTabIndex === -1) this.selectedTabIndex = 0;

        this.$store.dispatch('apiEvolution/getSettings', {
          instance: this.apiInstance,
          // inboxId: this.currentInboxId,
        });
        this.$store.dispatch('apiEvolution/getWoot', {
          instance: this.apiInstance,
        });
      }
      // this.$store.dispatch('apiEvolution/getSettings', {
      //   instance: this.apiInstance,
      //   inboxId: this.currentInboxId,
      // });
      this.loading = false;
    },
    currentInboxId() {
      this.selectedTabIndex = -1;
      if (this.inbox && this.inbox.webhook_url) {
        const url = this.inbox.webhook_url.split('/chatwoot/webhook/')[0];
        this.setUrlApiAxios(url);
        this.fetchInstance(this.inbox);
      }
    },
    inbox() {
      if (this.inbox.id) this.fetchInstance(this.inbox);
    },
  },
  mounted() {
    if (this.apiVersion) {
      this.fetchInstance(this.inbox);
    }
    if (this.inbox && this.inbox.webhook_url) {
      const url = this.inbox.webhook_url.split('/chatwoot/webhook/')[0];
      this.setUrlApiAxios(url);
    }
  },
  methods: {
    setUrlApiAxios(url, apikey) {
      apiEvolution.setUrlAxiosApi(url, apikey);
    },
    fetchInstance(inbox) {
      if (inbox) {
        this.loading = true;
        this.$store.dispatch('apiEvolution/getInstance', {
          inbox: inbox,
          versionApi: this.apiVersion,
        });
      }
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    showTab(tabName) {
      return (
        !this.instanceNoExist &&
        tabName === this.selectedTabKey &&
        this.apiInstance &&
        this.apiInstance.status
      );
    },
  },
};
</script>

<style scoped lang="scss">
.settings {
  .settings--tabs {
    ::v-deep .tabs {
      @apply p-0;
    }
  }
}
.loading-spinner {
  text-align: center;
  font-size: 18px;
  padding: 20px;
}
</style>
